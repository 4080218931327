import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Contact({ viewBox = "0 0 12 12", ...iconProps }: IconProps) {
  return (
    <Icon size={12} fill="none" {...iconProps} viewBox={viewBox}>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.5"
        d="M3 6h1.75M3 6L1.488 2.221a.5.5 0 01.688-.633l7.93 3.965a.5.5 0 010 .894l-7.93 3.965a.5.5 0 01-.688-.633L3 6z"
      ></path>
    </Icon>
  );
}
